import React, { useState } from "react";
import { graphql } from "gatsby";
import ProjectSlider from "components/ProjectSlider";
import Page from "components/Page";
import Header from "components/Header";
import Footer from "components/Footer";
import ReactRotatingText from "react-rotating-text";

const Index = (props) => {
	const { data } = props;
	const allPosts = data.allMarkdownRemark.edges;

	const emptyQuery = { string: "", tags: [] };

	const [state] = useState({
		query: emptyQuery,
	});

	const tagNames = new Set();
	allPosts.forEach((post) => {
		post.node.frontmatter.tags.forEach((tag) => {
			tagNames.add(tag);
		});
	});

	const { query } = state;
	let posts = data.allMarkdownRemark.edges || [];

	const filteredData = posts.filter((post) => {
		const { tags } = post.node.frontmatter;
		return (
			tags.filter((tag) => query.tags.includes(tag)).length > 0
		);
	});

	const hasSearchResults = filteredData && query.tags.length > 0;
	posts = hasSearchResults ? filteredData : allPosts;

	return (
		<>
			<Page
				minHeight="80vh"
				middle={(
					<>
						<h1>
							Work, that
							{" "}
							<ReactRotatingText items={["works", "delivers", "excites", "delights", "communicates", "functions", "stimulates"]} randomize color="var(--accent-color)" />
						</h1>
						<span>
							We help our customers realise their dreams. It&apos;s a cluttered world of
							agencies out there.	Mothership rises to the top by focusing on digital-first
							solutions, clean efficient design and cutting through the bs.
						</span>
					</>
				)}
			/>

			<Page
				middle={(
					<>
						{posts.map(({ node }) => {
							const {
								title, website, app, slides, colours, client, tags, featured
							} = node.frontmatter;
							return (
								<ProjectSlider
									title={title}
									website={website}
									app={app}
									slides={slides}
									colours={colours}
									client={client}
									tags={tags}
									featured={featured}
								/>
							);
						})}
					</>
				)}
			/>

			<Footer />
			<Header />
		</>
	);
};

export default Index;

export const pageQuery = graphql`
	query {
		allMarkdownRemark(
			filter: {frontmatter: {published: {eq: true}}}
			sort: {fields: frontmatter___priority, order: ASC}
			) {
			edges {
				node {
					id
					frontmatter {
						priority
						featured
						published
						slug
						title
						website
						app
						colours
						tags
						client
						slides
					}
				}
			}
		}
	}
`;
